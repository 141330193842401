import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper } from 'pieces';
import { motion } from 'framer-motion';
import { useNavigationContext } from './NavigationContext';

export const NavigationIcon = ({ OpenIcon, CloseIcon, ...props }) => {
  const {
    isOpen,
    closeNavigation,
    openNavigation,
    openIconControls,
    closeIconControls,
  } = useNavigationContext();

  return (
    <FlexWrapper
      as={motion.a}
      onClick={() => {
        if (isOpen) {
          closeNavigation();
        } else {
          openNavigation();
        }
      }}
      {...props}
    >
      <FlexWrapper sx={{ cursor: 'pointer' }} animate={openIconControls}>
        <OpenIcon />
      </FlexWrapper>
      <FlexWrapper
        sx={{ cursor: 'pointer' }}
        animate={closeIconControls}
        style={{ display: 'none' }}
      >
        <CloseIcon />
      </FlexWrapper>
    </FlexWrapper>
  );
};

NavigationIcon.propTypes = {
  OpenIcon: PropTypes.func.isRequired,
  CloseIcon: PropTypes.func.isRequired,
};
