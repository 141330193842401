import React from 'react';
import PropTypes from 'prop-types';

// pieces import
import { GridItem, GridWrapper } from 'pieces';

const NavigationLinkWrapper = ({ children }) => (
  <GridWrapper
    sx={{
      minHeight: '100%',
      width: '100%',
      bg: 'primary',
      overflow: 'scroll',
    }}
  >
    <GridItem
      className='overlay--wrapper'
      sx={{
        margin: [
          '1.5em 0.813em',
          '1.5em calc(3.75em - 15px)',
          '1.5em calc(3.875em - 15px)',
          '1.5em calc(2em - 15px)',
        ],
      }}
    >
      {children}
    </GridItem>
  </GridWrapper>
);

export default NavigationLinkWrapper;

NavigationLinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
