// external dependencies
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Box } from 'theme-ui';
import { useLocation } from '@gatsbyjs/reach-router';
import { useStaticQuery, graphql } from 'gatsby';
// pieces imports

import { animations as defaultAnimations } from 'pieces/navigation/simple/animations';
import { Overlay } from 'pieces';
// client imports
import {
  NavigationContextProvider,
  useNavigationContext,
} from './NavigationContext';
import { NavigationHeader } from './NavigationHeader';
import { NavigationLinks } from './NavigationLinks';

const NavigationOverlay = () => {
  // data
  const {
    navJson: {
      nav: { links },
    },
  } = useStaticQuery(graphql`
    query {
      navJson {
        nav {
          links {
            title
            to
            sublinks {
              title
              to
            }
          }
        }
      }
    }
  `);

  const { pathname } = useLocation();

  const {
    isOpen,
    closeNavigation,
    handleShowSublinks,
    variants,
    overlayControls,
  } = useNavigationContext();

  useEffect(() => {
    links.forEach(({ to, sublinks }) => {
      if (pathname.includes(to) && sublinks) {
        handleShowSublinks(to);
      }
    });
  }, []);

  return (
    <Overlay
      sx={{ zIndex: 120 }}
      preventBodyScrolling={!!isOpen}
      handleClose={() => {
        closeNavigation(!isOpen);
      }}
      variants={variants.overlay}
      animate={overlayControls}
      initial={variants.overlay.close}
    >
      <NavigationLinks />
    </Overlay>
  );
};

export const Navigation = memo(({ sx }) => (
  <NavigationContextProvider animations={defaultAnimations}>
    <Box
      id='nav'
      as={motion.nav}
      sx={{
        position: 'fixed',
        zIndex: '100',
        width: '100%',
        ...sx,
      }}
    >
      <NavigationHeader />
      <NavigationOverlay />
    </Box>
  </NavigationContextProvider>
));

Navigation.propTypes = {
  sx: PropTypes.shape(),
};

Navigation.defaultProps = {
  sx: {},
};
