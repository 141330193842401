// external dependencies
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// pieces imports
import { FlexWrapper, Box, NavigationLink } from 'pieces';
// client imports
import { useNavigationContext } from './NavigationContext';
import { OverlayHeader } from './OverlayHeader';
// component imports
import NavigationLinkWrapper from './NavigationLinkWrapper';
import NavigationLinkWithSublinks from './NavigationLinkWithSublinks';

export const NavigationLinks = () => {
  // data
  const {
    navJson: {
      nav: { links },
    },
  } = useStaticQuery(graphql`
    query {
      navJson {
        nav {
          links {
            title
            to
            sublinks {
              title
              to
            }
          }
        }
      }
    }
  `);

  const { closeNavigation } = useNavigationContext();

  return (
    <NavigationLinkWrapper>
      {/* ===== Overlay Header Component ===== */}
      <OverlayHeader />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <FlexWrapper
          sx={{
            textAlign: ['left', 'left', 'left', 'left', 'left', 'center'],
            flexWrap: 'nowrap',
            width: '100%',
            justifyContent: ['center', 'center', 'center', 'space-between'],
            flexDirection: ['column', 'column', 'column', 'row'],
          }}
        >
          {links.map(({ title, to, sublinks }) => {
            if (sublinks !== null) {
              // ===== Sublinks Component =====
              return (
                <NavigationLinkWithSublinks
                  title={title}
                  to={to}
                  sublinks={sublinks}
                  key={to}
                />
              );
            }

            // ====== Navigation Link that doesn't have subllink ======
            return (
              <Box
                onClick={closeNavigation}
                key={to}
                sx={{
                  // this makes the big as big as the link inside of it
                  p: ['2rem 0', '1.5em 0', '5vw 0', '0', '1rem 0'],
                }}
              >
                <NavigationLink
                  to={to}
                  sx={{
                    //   this enlarges the clickable area of the link
                    p: ['2rem 0', '1.5em 0', '5vw 0', '0', '1rem 0'],
                  }}
                >
                  {title}
                </NavigationLink>
              </Box>
            );
          })}
        </FlexWrapper>
      </Box>
    </NavigationLinkWrapper>
  );
};
