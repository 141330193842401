import React from 'react';
import { Box } from './Box';

export const InlineGridWrapper = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    {...props}
    variant='proportional.inline.autoColumns'
    __themeKey='grids'
    __css={{ display: 'grid', position: 'relative' }}
  />
));
