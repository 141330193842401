import React from 'react';
import PropTypes from 'prop-types';
// pieces import
import { Box, NavigationLink } from 'pieces';
// component import
import DesktopLinkWithSublink from './DesktopLinkWithSublink';
import { useNavigationContext } from './NavigationContext';

const DesktopNavLinks = ({ to, title, sublinks }) => {
  const { desktopNavHover } = useNavigationContext();

  return (
    <>
      {sublinks === null && (
        <Box
          key={to}
          sx={{
            p: '10px 1.5em',
          }}
        >
          <NavigationLink
            key={to}
            to={to}
            sx={{
              color: 'medium',
              width: 'max-content',

              '&:hover': {
                color: desktopNavHover ? 'white' : 'primary',
                // borderBottom: '4px solid #A4031C',
              },
              '&.active': {
                color: desktopNavHover ? 'white' : 'primary',
                borderBottom: desktopNavHover
                  ? '4px solid white'
                  : '4px solid #A4031C',
              },
            }}
          >
            {title}
          </NavigationLink>
        </Box>
      )}
      {sublinks !== null && (
        <DesktopLinkWithSublink to={to} title={title} sublinks={sublinks} />
      )}
    </>
  );
};

export default DesktopNavLinks;

DesktopNavLinks.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sublinks: PropTypes.arrayOf(PropTypes.object),
};

DesktopNavLinks.defaultProps = {
  sublinks: null,
};
