export const fontFamilies = {
  body: {
    normal: 'open_sansregular, sans-serif',
    bold: 'open_sansbold, sans-serif',
  },
  primary: {
    normal: 'tekoregular, sans-serif',
    // the bold style of this design doesn't use a different font
    // it's just red instead of black
    bold: 'tekoregular, sans-serif',
  },
  secondary: {
    normal: 'tekosemibold, sans-serif',
  },
};
