import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useThemeUI, merge } from 'theme-ui';
import { useAnimation } from 'framer-motion';

const NavigationContext = createContext();
export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationContextProvider = ({
  children,
  animations: baseAnimations,
}) => {
  const {
    theme: { animations: themeAnimations },
  } = useThemeUI();

  const [isOpen, setIsOpen] = useState(false);
  const [showSublinks, setShowSublinks] = useState([]);
  const [desktopNavHover, setDesktopNavHover] = useState(false);

  //  id = "/projekte"
  const handleShowSublinks = useCallback((id) => {
    // check if the id already exists in showSublinks
    const idExist = showSublinks.some((sublinkId) => sublinkId === id);

    if (idExist) {
      // if it exists:
      //   remove the id from the array => closing
      setShowSublinks(showSublinks.filter((sublinkId) => sublinkId !== id));
    } else {
      // if it does not exists
      //    push id to array => opening
      setShowSublinks([...showSublinks, id]);
    }
  });

  // Merging pieces animation with client specific theme animations
  const animations = merge(baseAnimations, themeAnimations);

  // theme animation style lookups
  const variants = {
    overlay: animations?.navigation?.overlay,
    linkItems: animations?.navigation?.links?.items,
  };
  // animation controls which get passed down to the client component
  // -> overlay
  const overlayControls = useAnimation();
  const controlOverlay = {
    open: () => overlayControls.start('open'),
    close: () => overlayControls.start('close'),
  };

  // Navigation Icon Animation
  const openIconControls = useAnimation();
  const closeIconControls = useAnimation();

  // helper functions that orchestrate all animated parts
  const closeNavigation = async () => {
    // Close overlay
    controlOverlay.close();
    // Switch icon
    openIconControls.start({ display: 'flex' });
    closeIconControls.start({ display: 'none' });
    // update isOpen
    setIsOpen(false);
  };
  const openNavigation = async () => {
    // Switch icon
    openIconControls.start({ display: 'none' });
    closeIconControls.start({ display: 'flex' });
    // update isOpen
    setIsOpen(true);
    // Open overlay
    await controlOverlay.open();
  };

  return (
    <NavigationContext.Provider
      value={{
        overlayControls,
        openNavigation,
        closeNavigation,
        variants,
        isOpen,
        openIconControls,
        closeIconControls,
        showSublinks,
        // setShowSublinks,
        handleShowSublinks,
        desktopNavHover,
        setDesktopNavHover,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  animations: PropTypes.shape(),
};

NavigationContextProvider.defaultProps = {
  animations: {},
};
