const linkDefault = {
  cursor: 'pointer',
};
const buttonDefault = {
  cursor: 'pointer',
  transition: '0.2s ease-out',
};

const links = {
  hyperLink: {
    ...linkDefault,
    variant: 'typography.hyperLink.normal',
    textDecoration: 'underline',
    ':hover,:focus,active': {
      color: 'medium',
    },
  },
  navigation: {
    ...linkDefault,
    variant: 'typography.navigationLink.normal',
    color: [
      'primaryTint',
      'primaryTint',
      'primaryTint',
      'primaryTint',
      'primaryTint',
      'medium',
    ],
    ':hover,:focus,&.active': {
      color: ['white', 'white', 'white', 'white', 'white', 'primary'],
      borderBottom: [
        '4px solid white',
        '4px solid white',
        '4px solid white',
        '4px solid white',
        '4px solid white',
        // For some reason, using border shorthand property can't detect theme UI
        null,
      ],
    },
  },
  subNavigation: {
    ...linkDefault,
    variant: 'typography.subNavigationLink.normal',
    color: ['white', 'white', 'white', 'white', 'medium', 'medium', 'medium'],
    ':hover,:focus,&.active': {
      color: ['white', 'white', 'white', 'white', 'white', 'dark', 'dark'],
      borderBottom: [
        '4px solid white',
        '4px solid white',
        '4px solid white',
        '4px solid white',
        '4px solid white',
        undefined,
        undefined,
      ],
    },
  },
  footer: {
    ...linkDefault,
    variant: 'typography.footerLink.normal',
    // color: 'medium',
    // ':hover,:focus,&.active': {
    //   color: 'dark',
    // },
  },
};

const buttons = {
  primary: {
    ...buttonDefault,
    bg: 'transparent',
    display: 'flex',
    alignItems: 'center',
    p: '0',

    '&:hover > div': {
      borderRadius: 'primaryButton',
      boxShadow: 'primaryButton',
      transition: '0.2s ease-out',
    },
  },
  secondary: {
    ...buttonDefault,
    bg: 'primary',
    borderRadius: [
      'secondaryButton1',
      'secondaryButton2',
      'secondaryButton2',
      'secondaryButton2',
      'secondaryButton2',
      'secondaryButton2',
    ],
    p: [
      '0.75rem 1.5rem 0.5rem',
      '0.75rem 2rem 0.75rem',
      '0.75rem 2rem 0.75rem',
      '0.75rem 2rem 0.75rem',
      '0.75rem 2rem 0.75rem',
      '0.75rem 2rem 0.75rem',
    ],

    '&:hover': {
      borderRadius: [null, null, null, null, null, '40px'],
      bg: 'primaryTint',
    },
  },
  tertiary: {
    ...buttonDefault,
    width: '100%',

    '&:hover > p': {
      color: 'primary',
      transition: '0.2s ease-out',
    },
  },
};

const cards = {
  primary: {
    backgroundColor: 'light',
    borderRadius: 'card',
    padding: '20px',
  },
  categoryCard: {
    p: '1.5em',
    boxShadow: 'card',
    borderRadius: '8px',
    display: ['block', 'block', 'flex', 'flex', 'block', 'block'],

    '&:hover': {
      boxShadow: '0px 24px 64px -4px rgba(0, 0, 0, 0.3)',
    },
  },
  mediaCard: {
    p: [
      '1.5em 1.5em 2.5em',
      '1.5em 1.5em 2.5em',
      '1.5em 1.5em',
      '1.5em 1.5em',
      '1.5em 1.5em 2.5em',
    ],
    boxShadow: '0px 12px 32px -4px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',

    '&:hover': {
      boxShadow: [
        null,
        null,
        null,
        '0px 24px 64px -4px rgba(0, 0, 0, 0.3)',
        '0px 24px 64px -4px rgba(0, 0, 0, 0.3)',
        '0px 24px 64px -4px rgba(0, 0, 0, 0.3)',
      ],
    },
  },

  awardCard: {
    p: ['0.5em', '0.75em', '1em', '0.75em', '0.75em', '0.75em'],
    boxShadow: 'awardCard',
    borderRadius: 'card',
  },
};

export const elements = {
  buttons,
  links,
  cards,
};
