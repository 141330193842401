// Inspired by https://github.com/segmentio/evergreen/blob/9fe04653cb11aff5d2604ba07308957d37a637f2/src/overlay/src/Overlay.js
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from './Box';
import { Portal } from './Portal';
import { safeInvoke, preventBodyScroll } from '../utils';
import { zIndex } from '../constants';

export const Overlay = memo(
  ({
    children,
    sx = {},
    preventBodyScrolling = false,
    shouldCloseOnBackdropClick = true,
    shouldCloseOnEscapePress = true,
    handleClose,
    ...props
  }) => {
    const close = () => {
      safeInvoke(handleClose);
    };

    const onEsc = (event) => {
      if (event.key === 'Escape' && shouldCloseOnEscapePress) {
        close();
      }
    };

    const handleBodyScroll = (preventScroll) => {
      if (preventBodyScrolling) {
        preventBodyScroll(preventScroll);
      }
    };

    useEffect(() => {
      handleBodyScroll(true);
      // TODO: bringFocusInside()
      document.body.addEventListener('keydown', onEsc, false);

      return () => {
        // Allow body to scroll and remove event listeners when component is unmounted
        handleBodyScroll(false);
        // TODO: bringFocusOutside()
        document.body.removeEventListener('keydown', onEsc, false);
      };
    }, [preventBodyScrolling]);

    const handleBackdropClick = (event) => {
      if (event.target !== event.currentTarget || !shouldCloseOnBackdropClick) {
        return;
      }

      close();
    };

    return (
      <Portal>
        <Box
          id='overlay-background'
          onClick={handleBackdropClick}
          sx={{
            zIndex: zIndex.OVERLAY,
            top: 0,
            left: 0,
            position: 'fixed',

            // default overlay
            display: 'block',
            width: '100%',
            height: '100%',

            // background color
            bg: 'background.overlay',
            ...sx,
          }}
          {...props}
        >
          {children}
        </Box>
      </Portal>
    );
  }
);

Overlay.propTypes = {
  /**
   * Children are react nodes
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Styling passed to the background box.
   */
  // eslint-disable-next-line react/require-default-props
  sx: PropTypes.shape(),

  /**
   * Whether or not to prevent body scrolling outside the context of the overlay
   */
  // eslint-disable-next-line react/require-default-props
  preventBodyScrolling: PropTypes.bool,

  /**
   * Boolean indicating if clicking the overlay should close the overlay.
   */
  // eslint-disable-next-line react/require-default-props
  shouldCloseOnBackdropClick: PropTypes.bool,

  /**
   * Boolean indicating if pressing the esc key should close the overlay.
   */
  // eslint-disable-next-line react/require-default-props
  shouldCloseOnEscapePress: PropTypes.bool,
  /**
   * Function to be called when background or escape key is clicked
   */
  handleClose: PropTypes.func.isRequired,
};
