// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  background: '#fff',
  primary: '#A4031C',
  primaryTint: '#C86877',
  secondary: '#2C2C2C',
  disabled: '#000',

  // neutrals
  black: '#000000',
  dark: '#2D3133',
  medium: '#D1D4D7',
  light: '#F2F3F8',
  gray50: '#FAFAFA',
  gray100: '#F5F5F5',
  gray300: '#A2A2A2',
};

const textColors = {
  text: '#2C2C2C',
  // textBold: 'red',
  // textItalic
  heading: '#000',
  // headingBold: 'green',
  // headingItalic
  // labels: 'green',
};
export const colors = {
  // defaults
  ...base,
  ...textColors,
};
