import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link as ThemeLink, Text } from 'theme-ui';
// pieces components
import { FlexWrapper, Box, FlexItem, Image, Paragraph } from 'pieces';
// local imports
import { NavigationIcon } from './NavigationIcon';
import { usePageContext } from '../../pieces/utils/context/PageContext';

export const OverlayHeader = memo(() => {
  const { openIcon, closeIcon } = useStaticQuery(graphql`
    query {
      openIcon: file(relativePath: { eq: "icons/menu-icon.svg" }) {
        id
        publicURL
      }
      closeIcon: file(relativePath: { eq: "icons/close-icon-overlay.svg" }) {
        id
        publicURL
      }
    }
  `);

  const { locale } = usePageContext();

  return (
    <div>
      {/* Overlay Header Start */}
      <FlexWrapper
        sx={{
          my: '1.5em',
          justifyContent: 'space-between',
        }}
      >
        {/* Translate Button Start */}
        <FlexItem>
          <Text
            sx={{
              textTransform: 'uppercase',
              color: 'white',
              fontSize: '.75rem',
              letterSpacing: '2px',
            }}
          >
            sprache
          </Text>
          <FlexWrapper
            sx={{
              marginTop: '0.375em',
            }}
          >
            <ThemeLink
              // onClick={() => handleShowSublinks()}
              variant='typography.navigationLink.normal'
              sx={{
                color: locale === 'de' ? 'white' : 'primaryTint',
                width: 'fit-content',
                paddingBottom: '0.188em',
                borderBottom: locale === 'de' ? '4px solid white' : null,
                marginRight: '.75rem',
              }}
            >
              DE
            </ThemeLink>
            <ThemeLink
              // onClick={() => handleShowSublinks()}
              variant='typography.navigationLink.normal'
              sx={{
                color: locale === 'en' ? 'white' : 'primaryTint',
                width: 'max-content',
                paddingBottom: '0.188em',
                borderBottom: locale === 'en' ? '4px solid white' : null,
              }}
            >
              EN
            </ThemeLink>
          </FlexWrapper>
          <Box
            sx={{
              marginTop: '1.5em',
            }}
          >
            <Paragraph
              sx={{
                color: 'white',
                fontSize: ['0.875rem', '1rem'],
              }}
            >
              +49 (0)6144 20999-0
            </Paragraph>
            <Paragraph
              sx={{
                color: 'white',
                fontSize: ['0.875rem', '1rem'],
              }}
            >
              info@mdl-expo.com
            </Paragraph>
          </Box>
        </FlexItem>
        {/* Translate Button End */}
        {/* Close Icon Start */}
        <FlexItem
          sx={{
            position: 'fixed',
            right: ['1.75em', '3.75em', '3.875em'],
            textAlign: 'center',
          }}
        >
          <NavigationIcon
            OpenIcon={() => (
              <Box
                sx={{
                  alignSelf: 'center',
                  p: [
                    '0 40px 4px 0',
                    '0 40px 4px 0',
                    '0 40px 4px 0',
                    '0 40px 4px 0',
                  ],
                  mr: '-40px',
                }}
              >
                <Image
                  sx={{
                    color: 'primary',
                    height: ['19px', '19px', '19px', '19px'],
                  }}
                  src={openIcon.publicURL}
                />
              </Box>
            )}
            CloseIcon={() => (
              <Box
                sx={{
                  alignSelf: 'center',
                  p: [
                    '0 40px 4px 0',
                    '0 40px 4px 0',
                    '0 40px 4px 0',
                    '0 40px 4px 0',
                  ],
                  mr: '-40px',
                }}
              >
                <Image
                  sx={{
                    height: ['20px', '20px', '30px', '30px'],
                  }}
                  src={closeIcon.publicURL}
                />
              </Box>
            )}
          />
          <Text
            sx={{
              textTransform: 'uppercase',
              color: 'white',
              writingMode: 'vertical-rl',
              transform: 'rotate(-180deg)',
              textAlign: 'right',
              fontSize: '.75rem',
              letterSpacing: '1px',
            }}
          >
            schliessen
          </Text>
        </FlexItem>
        {/* Close Icon End */}
      </FlexWrapper>
      {/* Overlay Header End */}
    </div>
  );
});
