import React from 'react';
import propTypes from 'prop-types';
import { Text } from 'theme-ui';
import { Box, NavigationLink, FlexWrapper, FlexItem } from 'pieces';
import { useNavigationContext } from './NavigationContext';

const DesktopLinkWithSublink = ({ to, title, sublinks }) => {
  const { desktopNavHover, setDesktopNavHover } = useNavigationContext();
  const ref = React.useRef();

  return (
    <Box
      ref={ref}
      key={to}
      sx={{
        p: '10px 1.5em',
        position: 'relative',
      }}
      onMouseEnter={() => setDesktopNavHover(true)}
      onMouseLeave={() => setDesktopNavHover(false)}
    >
      <Text
        variant='typography.navigationLink.normal'
        sx={{
          color: 'medium',
          width: 'max-content',

          '&:hover': {
            color: desktopNavHover ? 'white' : 'primary',
          },
        }}
      >
        {title}
      </Text>
      {desktopNavHover && sublinks && (
        <FlexWrapper
          sx={{
            flexDirection: 'column',
            position: 'absolute',
            pt: '1.5em',
            bg: 'primary',
            width: '100vw',
            ...(ref.current
              ? {
                  'margin-left': `-${
                    ref.current.getBoundingClientRect().left
                  }px`,
                  'padding-left': `${
                    ref.current.getBoundingClientRect().left
                  }px`,
                }
              : { display: 'none' }),
          }}
          onMouseEnter={() => setDesktopNavHover(true)}
        >
          {sublinks.map(({ to: sublinkTo, title: sublinkTitle }) => (
            <FlexItem
              sx={{
                marginBottom: '1em',
              }}
              key={sublinkTo}
            >
              <NavigationLink
                to={sublinkTo}
                key={sublinkTo}
                sx={{
                  color: desktopNavHover ? 'primaryTint' : 'medium',
                  width: 'max-content',
                  whiteSpace: 'nowrap',

                  '&:hover': {
                    color: desktopNavHover ? 'white' : 'primary',
                    borderBottom: '4px solid white',
                  },
                  '&.active': {
                    color: desktopNavHover ? 'white' : 'primary',
                    borderBottom: desktopNavHover
                      ? '4px solid white'
                      : '4px solid #A4031C',
                  },
                }}
              >
                {sublinkTitle}
              </NavigationLink>
            </FlexItem>
          ))}
        </FlexWrapper>
      )}
    </Box>
  );
};

export default DesktopLinkWithSublink;

DesktopLinkWithSublink.propTypes = {
  to: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  sublinks: propTypes.arrayOf(propTypes.object).isRequired,
};
