// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projekte-ladenbau-js": () => import("./../../../src/pages/projekte/ladenbau.js" /* webpackChunkName: "component---src-pages-projekte-ladenbau-js" */),
  "component---src-pages-projekte-messebau-js": () => import("./../../../src/pages/projekte/messebau.js" /* webpackChunkName: "component---src-pages-projekte-messebau-js" */),
  "component---src-pages-projekte-renovierungen-js": () => import("./../../../src/pages/projekte/renovierungen.js" /* webpackChunkName: "component---src-pages-projekte-renovierungen-js" */),
  "component---src-pages-projekte-set-und-eventbau-js": () => import("./../../../src/pages/projekte/set-und-eventbau.js" /* webpackChunkName: "component---src-pages-projekte-set-und-eventbau-js" */)
}

