export const typographySetup = {
  // implemented
  h1: {
    factor: 2.5,
    fontSizes: [59.6, 63.94, 64.95, 44.93, 59.03, 81.06],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
    paddingBottom: [24, 24, 24, 24, 24, 24],
    paddingTop: [8, 8, 8, 8, 8, 8],
  },
  // implemented
  h2: {
    factor: 2.5,
    fontSizes: [52.15, 56.83, 76.42, 52.42, 51.65, 90.95],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
    paddingBottom: [24, 24, 24, 24, 24, 24],
    paddingTop: [8, 8, 8, 8, 8, 8],
  },
  // implemented
  h3: {
    factor: 2.5,
    fontSizes: [37.25, 39.07, 37.44, 38.21, 40.59, 47.45],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
  },
  h3Emphasized: {
    factor: 2.5,
    fontSizes: [54.01, 74.59, 93.6, 68.77, 73.79, 88.97],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
  },
  // implemented
  h4: {
    factor: 2.5,
    fontSizes: [20.49, 21.31, 22.93, 20.59, 22.14, 23.73],
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
  },
  h5: {
    factor: 2.5,
    fontSizes: [16.76, 15.98, 20.59, 17.19, 20.29, 23.73],
    fontFamily: 'primary',
    fontWeight: 'bold',
    lineHeight: 'heading.safe',
    color: 'white',
    textTransform: 'uppercase',
    letterSpacing: '0.75px',
  },
  h6: {
    factor: 2.5,
    fontSizes: [20.49, 21.31, 22.46, 21.01, 22.14, 23.73],
    fontFamily: 'primary',
    color: 'secondary',
    lineHeight: 'heading.safe',
  },
  // implemented
  quote: {
    factor: 2.5,
    // fontSizes: [26.08, 28.42, 34.39, 29.95, 33.21, 35.59],
    // I'm adjusting this according to the new design
    fontSizes: [29.8, 39.07, 37.44, 34.39, 36.9, 39.54],
    fontFamily: 'primary',
    lineHeight: 'heading.large',
  },
  // implemented
  quoteAuthor: {
    factor: 2.5,
    fontSizes: [13.04, 12.43, 14.33, 13.1, 13.84, 14.83],
    fontFamily: 'body',
    lineHeight: 'heading.safe',
  },
  navigationLink: {
    factor: 2.5,
    fontSizes: [30, 36, 36, 30, 30, 20],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
  },
  subNavigationLink: {
    factor: 2.5,
    fontSizes: [23.4, 30, 30, 24, 24, 16],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
  },
  footerLink: {
    factor: 2.5,
    fontSizes: [11.18, 10.66, 13.1, 11.46, 12.91, 13.84],
    fontFamily: 'body',
    lineHeight: 'body.safe',
    color: 'white',
  },
  hyperLink: {
    factor: 2.5,
    fontSizes: [21.6, 19.2, 24.8, 24.8, 24],
    fontFamily: 'body',
    lineHeight: 'heading.safe',
  },
  // implemented
  buttonSecondary: {
    factor: 2.5,
    fontSizes: [20.49, 21.31, 24.34, 19.1, 20.29, 31.63],
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
    letterSpacing: '1px',
  },
  // implemented
  buttonPrimary: {
    factor: 2.5,
    fontSizes: [22.35, 21.31, 26.21, 21.01, 25.83, 29.66],
    fontFamily: 'primary',
    lineHeight: 'heading.design',
  },
  // implemented
  buttonTertiary: {
    factor: 2.5,
    fontSizes: [18.63, 17.76, 20.59, 21.01, 20.29, 21.75],
    fontFamily: 'primary',
    lineHeight: 'heading.button',
  },
  label: {
    factor: 2.5,
    fontSizes: [18.63, 17.76, 18.72, 19.1, 18.45, 19.77],
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
  },
  // implemented
  paragraph: {
    factor: 2.5,
    fontSizes: [14.9, 15.98, 14.98, 15.28, 13.84, 14.83],
    fontFamily: 'body',
    lineHeight: 'body.safe',
  },
  // implemented
  leadParagraph: {
    factor: 2.5,
    fontSizes: [14.9, 17.76, 15.28, 14.98, 14.76, 15.82],
    fontFamily: 'body',
    lineHeight: [
      'body.safe',
      'body.safe',
      'body.safe',
      'body.safe',
      'body.large',
      'body.large',
    ],
  },
  // implemented
  cardTitle: {
    factor: 2.5,
    fontSizes: [33.53, 33.74, 37.44, 36.3, 33.21, 39.54],
    color: 'secondary',
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
  },
  cardTitleSecondary: {
    factor: 2.5,
    fontSizes: [37.25, 35.52, 37.44, 36.3, 33.21, 39.54],
    color: 'secondary',
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
  },
  // implemented
  cardParagraph: {
    factor: 2.5,
    fontSizes: [13.97, 15.1, 14.98, 15.28, 13.84, 13.84],
    opacity: '0.8',
    fontFamily: 'body',
    lineHeight: 1.65,
  },
  awardTitle: {
    factor: 2.5,
    fontSizes: [20.49, 22.2, 22.46, 22.93, 22.14, 24.71],
    color: 'seconday',
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
  },
  contactDataHeadline: {
    factor: 2.5,
    fontSizes: [33.53, 33.74, 37.44, 36.3, 33.21, 39.54],
    fontFamily: 'primary',
    lineHeight: 'heading.safe',
  },
  cardTemp: {
    factor: 2.5,
    fontSizes: [13.11],
    fontFamily: 'body',
    fontWeight: 'bold',
    lineHeight: 'heading.safe',
  },
};

export const lineHeights = {
  heading: {
    safe: 1,
    design: 0.9,
    large: 1.25,
  },
  body: {
    large: 2,
    safe: 1.8,
    design: 1.5,
    button: 1.45,
  },
};
