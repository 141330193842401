// External dependencies
import React from 'react';
// Pieces imports
import { Box } from './Box';

export const Card = React.forwardRef((props, ref) => (
  <Box
    className='card--background'
    ref={ref}
    variant='primary'
    {...props}
    __themeKey='cards'
    __css={{ backgroundColor: '#fff' }}
  />
));
