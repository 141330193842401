// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// Pieces components
// import { usePageContext } from 'pieces/utils/context';
import {
  Section,
  NavigationLink,
  GridItem,
  FlexWrapper,
  Heading,
  Image,
  Paragraph,
} from 'pieces';

// Local Component import
// import { ThePuzzlersLink } from './ThePuzzlesLink';

//
// Multi Language Footer
//
// export const Footer = memo(() => {
//   const { locale } = usePageContext();
//   const { enNavLinks, deNavLinks } = useStaticQuery(graphql`
//     query {
//       enNavLinks: navJson {
//         footer {
//           links {
//             title
//             to
//           }
//         }
//       }
//       deNavLinks: deNavJson {
//         footer {
//           links {
//             title
//             to
//           }
//         }
//       }
//     }
//   `);

//   // Set footer links based on locale
//   const { footer: { links = [] } = {} } =
//     locale === 'de' ? deNavLinks : enNavLinks;

//   return (
//     <Section as='footer' sx={{ mb: ['20vw', '10vw', '0', '0', '2vw'] }}>
//       {/* Footer Content Wrapper */}
//       <GridItem
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1, transition: { delay: 1, duration: 2 } }}
//         sx={{
//           display: 'flex',
//           flexDirection: ['column', 'column', 'row', 'row', 'row'],
//           textAlign: ['center', 'center', 'center', 'left', 'left'],
//           justifyContent: 'space-between',
//           alignItems: ['center', 'center', 'flex-end', 'flex-end', 'flex-end'],
//           margin: [
//             '10vw 0 4vw',
//             '5vw 0',
//             '10vw 0',
//             '10vw 0 5vw 0',
//             '5vw 0 2vw 0',
//           ],
//         }}
//       >
//         {/* Loop through Footer Links */}
//         <FlexWrapper
//           sx={{ flexDirection: ['column', 'column', 'row', 'row', 'row'] }}
//         >
//           {links.map(link => (
//             <GatsbyLink
//               sx={{
//                 marginRight: [0, 0, 20, 20, 25],
//                 marginBottom: [20, 10, 0, 0, 0],
//               }}
//               key={link.to}
//               to={link.to}
//               variant='footer'
//             >
//               {link.title}
//             </GatsbyLink>
//           ))}
//         </FlexWrapper>
//         {/* "Built by the Puzzlers" - Reference */}
//         <ThePuzzlersLink />
//       </GridItem>
//     </Section>
//   );
// });

//
// Single Language Footer
//
export const Footer = memo(() => {
  const {
    navJson: {
      footer: {
        pages: { title: pagesTitle, links: pagesLinks },
        chances: { title: chancesTitle, links: chancesLinks },
        companyData: {
          title: companyDataTitle,
          name: companyName,
          address: companyAddress,
          contact: {
            phone: companyPhone,
            fax: companyFax,
            email: companyEmail,
          },
        },
      },
    },
    facebook: { publicURL: fbIcon },
    instagram: { publicURL: igIcon },
  } = useStaticQuery(graphql`
    {
      navJson {
        footer {
          pages {
            links {
              title
              to
            }
            title
          }
          chances {
            links {
              title
              to
            }
            title
          }
          companyData {
            title
            name
            address
            contact {
              phone
              fax
              email
            }
          }
        }
      }
      facebook: file(relativePath: { eq: "icons/facebook.svg" }) {
        publicURL
      }
      instagram: file(relativePath: { eq: "icons/instagram.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Section
      as='footer'
      sx={{
        // mb: ['20vw', '10vw', '0', '0', '2vw'],
        background: 'linear-gradient(48.17deg, #1F3942 -1.1%, #111F27 99.62%)',
        pt: [48, 54, 50, 50, 56, 60],
        pb: [48, 42, 34, 44, 48, 50],
      }}
    >
      {/* Seiten Content Wrapper */}
      <GridItem
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1, duration: 2 } }}
        sx={{
          display: 'flex',
          gridColumn: [
            '1/span 5',
            '1/span 3',
            '1/span 5',
            '1/span 5',
            '1/span 5',
            '1/span 3',
          ],
        }}
      >
        {/* Loop through Footer Links defined in nav.json */}
        <FlexWrapper sx={{ flexDirection: 'column' }}>
          <Heading type='h5' as='h5'>
            {pagesTitle}
          </Heading>
          {pagesLinks.map((link) => (
            <NavigationLink
              sx={{
                my: 6,
              }}
              key={link.to}
              to={link.to}
              variant='footer'
            >
              {link.title}
            </NavigationLink>
          ))}
        </FlexWrapper>
      </GridItem>
      {/* Chancen Content Wrapper */}
      <GridItem
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1, duration: 2 } }}
        sx={{
          display: 'flex',
          gridRowStart: 1,
          gridColumn: [
            '7/span 6',
            '4/span 3',
            '9/span 5',
            '9/span 5',
            '6/span 4',
            '7/span 3',
          ],
        }}
      >
        <FlexWrapper sx={{ flexDirection: 'column' }}>
          <Heading type='h5' as='h5'>
            {chancesTitle}
          </Heading>
          {chancesLinks.map((link) => (
            <NavigationLink
              sx={{
                my: 6,
              }}
              key={link.to}
              to={link.to}
              variant='footer'
            >
              {link.title}
            </NavigationLink>
          ))}
        </FlexWrapper>
      </GridItem>
      {/* Social network content wrapper */}
      <GridItem
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1, duration: 2 } }}
        sx={{
          display: 'flex',
          mt: [102, 102, 120, 120, 0, 0],
          gridRowStart: 1,
          gridColumn: [
            '7/span 6',
            '4/span 4',
            '9/span 7',
            '9/span 6',
            '20/span 5',
            '21/span 4',
          ],
        }}
      >
        <FlexWrapper sx={{ flexDirection: 'column' }}>
          <Heading type='h5' as='h5'>
            Soziale Netzwerke
          </Heading>
          <FlexWrapper sx={{ mt: [16, 16, 12, 16, 16, 12] }}>
            <Image src={fbIcon} sx={{ mr: 24 }} />
            <Image src={igIcon} />
          </FlexWrapper>
        </FlexWrapper>
      </GridItem>
      {/* Company data content wrapper */}
      <GridItem
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1, duration: 2 } }}
        sx={{
          display: 'flex',
          gridRowStart: [2, 1],
          gridColumn: [
            '1/span 9',
            '8/span 5',
            '17/span 8',
            '18/span 7',
            '12/span 6',
            '13/span 5',
          ],
          mt: [40, 0],
        }}
      >
        <FlexWrapper sx={{ flexDirection: 'column' }}>
          <Heading type='h5' as='h5'>
            {companyDataTitle}
          </Heading>
          <Paragraph type='footerLink' sx={{ fontWeight: 'bold', mt: 8 }}>
            {companyName}
          </Paragraph>
          <Paragraph type='footerLink' sx={{ mt: 24 }}>
            {companyAddress}
          </Paragraph>
          <Paragraph type='footerLink' sx={{ mt: 24 }}>
            {companyPhone}
          </Paragraph>
          <Paragraph type='footerLink'>{companyFax}</Paragraph>
          <Paragraph type='footerLink'>{companyEmail}</Paragraph>
        </FlexWrapper>
      </GridItem>
    </Section>
  );
});
