export const animations = {
  // simple navigation overrides
  //   navigation: {
  //     overlay: {
  //       open: {
  //         backgroundColor: '#E5005B',
  //         color: 'white',
  //       },
  //     },
  //     close: {},
  //   },
  //   links: {
  //     items: {
  //       open: {},
  //       close: {},
  //     },
  //   },
};
