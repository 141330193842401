// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
// pieces imports
import { usePageContext } from 'pieces/utils/context';
import { localizePath } from 'pieces/utils';
// local imports
import { useNavigationContext } from './NavigationContext';

export const NavigationLogo = ({ Logo }) => {
  const { closeNavigation, isOpen } = useNavigationContext();
  const { locale } = usePageContext();
  // Possible improvement:
  // accept Logo as childrens prop
  //   would require a React.cloneElement func to pass the onClick prop
  return (
    <Logo
      style={{ cursor: 'pointer' }}
      onClick={() => {
        navigate(localizePath('/', locale));
        if (isOpen) {
          closeNavigation();
        }
      }}
    />
  );
};

NavigationLogo.propTypes = {
  Logo: PropTypes.func.isRequired,
};
