export const styles = {
  shadows: {
    image: '0px 8px 24px rgba(0, 0, 0, 0.25)',
    card: '0px 12px 32px -4px rgba(0, 0, 0, 0.3)',
    primaryButton: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    awardCard: '0px 8px 16px -4px rgba(0, 0, 0, 0.16)',
  },
  radii: {
    card: '8px',
    button: '5px',
    primaryButton: '50% 50% 50% 50%',
    secondaryButton1: '16px 0 0 0',
    secondaryButton2: '24px 0 0 0',
  },
};
