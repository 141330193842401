/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { GatsbyImage as Image } from 'gatsby-plugin-image';

import { Box } from './Box';

export const GatsbyImage = ({ sx, image, alt, loading, ...props }) => (
  <Box sx={sx} {...props}>
    <Image
      image={image.childImageSharp.gatsbyImageData}
      alt={alt}
      loading={loading}
      style={{
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        borderRadius: 'inherit',
      }}
      imgStyle={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      }}
      // IE polyfill
      objectFit='contain'
    />
  </Box>
);

GatsbyImage.propTypes = {
  sx: PropTypes.shape(),
  alt: PropTypes.string.isRequired,
  image: PropTypes.shape().isRequired,
  loading: PropTypes.string,
};

GatsbyImage.defaultProps = {
  sx: {},
  loading: '',
};
