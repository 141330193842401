import React from 'react';
import propTypes from 'prop-types';
import { useLocation } from '@gatsbyjs/reach-router';
// pieces imports
import { Box, NavigationLink } from 'pieces';
// client imports
import { useNavigationContext } from './NavigationContext';

function isLinkOpen(showSublinks, linkTo) {
  return showSublinks.find((sublinkId) => sublinkId === linkTo);
}

// currentActivePathname = '/about'
// currentActivePathname = '/about/'
// to = '/projekte/team'
function isSubPageActive(currentActivePathname, to) {
  return currentActivePathname === to || currentActivePathname === `${to}/`;
}

// Component start
const NavigationLinkWithSublinks = ({ title, to, sublinks }) => {
  const { pathname } = useLocation();
  const {
    closeNavigation,
    handleShowSublinks,
    showSublinks,
  } = useNavigationContext();

  return (
    <Box
      key={to}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        height: 'auto',
        p: ['2em 0', '1.5em 0', '5vw 0', '0 0', '1rem 0'],
        position: ['static', 'static', 'static', 'relative'],
      }}
    >
      {/* Parent Link Name */}
      <Box
        as='p'
        onClick={() => {
          handleShowSublinks(to);
        }}
        variant='typography.navigationLink.normal'
        sx={{
          // is "/projekte" currently open
          // showSublinks: ['/projekte', '/unternehmen']
          color: isLinkOpen(showSublinks, to) ? 'white' : 'primaryTint',
          width: 'fit-content',
          borderBottom: isSubPageActive(pathname, to)
            ? '4px solid white'
            : undefined,
        }}
      >
        {title}
      </Box>
      {/* only show the sublinks if the link is open */}
      {isLinkOpen(showSublinks, to) &&
        // map through all the sublinks of parent
        // render NaviagtionLink for each sublink
        sublinks.map(({ to: subLinkTo, title: subLinkTitle }) => (
          <Box
            onClick={closeNavigation}
            key={subLinkTo}
            sx={{
              // this makes the big as big as the link inside of it
              p: ['1rem 0', '1.5em 0', '2vw 0', '1.5em 0', '1.5em 0'],
              margin: '0.25rem 0',
            }}
          >
            {/* this enlarges the clickable area of the link */}
            <NavigationLink
              variant='subNavigation'
              to={subLinkTo}
              sx={{
                p: ['0.75rem 0', '0.5em 0', '2vw 0', '1.5em 0', '1.5em 0'],
                position: ['static', 'static', 'static', 'absolute'],
                whiteSpace: ['normal', 'normal', 'normal', 'nowrap', 'nowrap'],
              }}
            >
              {subLinkTitle}
            </NavigationLink>
          </Box>
        ))}
    </Box>
  );
};

export default NavigationLinkWithSublinks;

NavigationLinkWithSublinks.propTypes = {
  title: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
  sublinks: propTypes.arrayOf(propTypes.object).isRequired,
};
