// External dependencies
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// Pieces imports
import { GridWrapper, GridItem, FlexWrapper, Box, Image } from 'pieces';
// Local imports
import { DesktopNavigationLinks } from './DesktopNavigationLinks';
import { NavigationIcon } from './NavigationIcon';
import { NavigationLogo } from './NavigationLogo';
import { useNavigationContext } from './NavigationContext';

export const NavigationHeader = () => {
  // Data
  const { logo, openIcon, closeIcon, logoHover } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "company/company-logo.svg" }) {
        id
        publicURL
      }
      openIcon: file(relativePath: { eq: "icons/menu-icon.svg" }) {
        id
        publicURL
      }
      closeIcon: file(relativePath: { eq: "icons/close-icon.svg" }) {
        id
        publicURL
      }
      logoHover: file(relativePath: { eq: "company/company-logo-white.svg" }) {
        id
        publicURL
      }
    }
  `);

  const { desktopNavHover, setDesktopNavHover } = useNavigationContext();

  return (
    <>
      <GridWrapper
        onMouseEnter={() => setDesktopNavHover(true)}
        onMouseLeave={() => setDesktopNavHover(false)}
        className='navigation--wrapper'
        sx={{
          bg: desktopNavHover ? 'primary' : 'white',
          p: ['1.5em 1.75em 0', '1.5em 3.75em 0', '1.5em 3.875em 0'],
        }}
        animate={{ opacity: 1, transition: { duration: 1, delay: 0 } }}
        initial={{ opacity: 0 }}
      >
        <GridItem
          as='nav'
          sx={{
            height: ['60px', '60px', '60px', '60px', '70px'],
            gridRow: '1',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <NavigationLogo
            Logo={(props) => (
              <Image
                sx={{
                  height: ['23px', '23px', '23px', '23px', '25px'],
                  alignSelf: 'center',
                }}
                src={desktopNavHover ? logoHover.publicURL : logo.publicURL}
                {...props}
              />
            )}
          />
          {/* Mobile Navigtion Bar */}
          {/* Open Close Icons */}
          <FlexWrapper
            className='navigation--open-close-icons'
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              display: ['flex', 'flex', 'flex', 'flex', 'flex', 'none'],
              height: '100%',
            }}
          >
            <NavigationIcon
              OpenIcon={() => (
                <Box
                  sx={{
                    alignSelf: 'center',
                    p: [
                      '20px 40px 20px 20px',
                      '20px 40px 20px 40px',
                      '20px 40px 20px 40px',
                      '20px 40px 20px 40px',
                    ],
                    mr: '-40px',
                  }}
                >
                  <Image
                    sx={{
                      color: 'primary',
                      height: ['19px', '19px', '19px', '19px'],
                    }}
                    src={openIcon.publicURL}
                  />
                </Box>
              )}
              CloseIcon={() => (
                <Box
                  sx={{
                    alignSelf: 'center',
                    p: [
                      '20px 40px 20px 20px',
                      '20px 40px 20px 40px',
                      '20px 40px 20px 40px',
                      '20px 40px 20px 40px',
                    ],
                    mr: '-40px',
                  }}
                >
                  <Image
                    sx={{
                      height: ['20px', '20px', '30px', '30px'],
                    }}
                    src={closeIcon.publicURL}
                  />
                </Box>
              )}
            />
          </FlexWrapper>
          {/* Desktop Navigation Links */}
          <DesktopNavigationLinks />
        </GridItem>
      </GridWrapper>
    </>
  );
};
