import React from 'react';
import PropTypes from 'prop-types';
import { Box } from './Box';

export const GridWrapper = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    variant={
      !props.gridLayout
        ? 'proportional.outside.columns'
        : `proportional.${props.gridLayout}`
    }
    {...props}
    __themeKey='grids'
    __css={{ display: 'grid', position: 'relative' }}
  />
));

GridWrapper.propTypes = {
  gridLayout: PropTypes.string,
};

GridWrapper.defaultProps = {
  gridLayout: undefined,
};
