export const forms = {
  input: {
    variant: 'typography.paragraph.normal',
    color: 'text',
    backgroundColor: 'gray100',
    mt: 8,
    padding: '12px 16px',
    borderRadius: '5px',
    '::placeholder': {
      color: 'gray300',
    },
  },
};
